import Vue from "vue";
import axios from "axios";
import { apolloProvider } from "~/graphql/graphql";
import gql from "graphql-tag";
import ReLoginOverlay from "~/components/common/layout/ReLoginOverlay";
import { configureVue } from "~/util/setup-vue";

let local_auth_key = "auth";
let local_refreshing_key = "refreshing";

export function setAuthSuffix(suffix) {
  local_auth_key += suffix;
  local_refreshing_key += suffix;
}

const TAB_ID = `${Math.random().toString(36)}${Math.random().toString(36)}`;

function persist(data) {
  if (data) {
    localStorage.setItem(local_auth_key, JSON.stringify(data));
  } else {
    localStorage.removeItem(local_auth_key);
  }
}

function load(prop = null) {
  const rawData = localStorage.getItem(local_auth_key);
  if (rawData) {
    try {
      const data = JSON.parse(rawData);
      return prop ? data[prop] || null : data;
    } catch (e) {
      return prop ? null : {};
    }
  } else {
    return prop ? null : {};
  }
}

export function getJwtToken() {
  return load("authToken");
}

configureVue();

export const ReactiveAuth = new Vue({
  name: "Auth",
  components: { ReLoginOverlay },
  data() {
    return {
      requiredRole: null,
      inited: false,
      authToken: null,
      refreshToken: null,
      userId: null,
      error: null,
      loading: false,
      exp: null,
      name: null,
      email: null,
      role: null,
      rights: [],
      lastAuthEmail: null,
    };
  },
  computed: {
    loggedIn() {
      return !!this.authToken;
    },
    loggingIn() {
      return this.loading;
    },
    safeName() {
      return this.name || "";
    },
    reLoginVisible() {
      const { inited, loggedIn, lastAuthEmail } = this;
      return inited && !loggedIn && lastAuthEmail;
    },
  },
  methods: {
    setRequiredRole({ role }) {
      this.requiredRole = role;
    },
    hasRight(right) {
      return (
        !!this.authToken &&
        this.rights &&
        (this.rights.indexOf(right) > -1 || this.rights.indexOf("all") > -1)
      );
    },
    wrapLoginAxiosRequest(axiosRequest, ignoreErrors = false) {
      return axiosRequest
        .then((response) => {
          if (response.data.id && response.headers.authorization) {
            if (this.requiredRole === response.data.role) {
              this.setStateLogin({
                authToken: response.headers.authorization.split(" ", 2)[1],
                refreshToken: response.headers["x-refresh-token"],
                userId: response.data.id.toString(),
                role: response.data.role,
                rights: response.data.rights,
                name: response.data.name,
                email: response.data.email,
              });
            } else {
              throw new Error(
                `You are role ${response.data.role} but this login is for ${this.requiredRole}`
              );
            }
          } else {
            console.error(response);
            if (!ignoreErrors) {
              this.setStateLoginFailure({
                error: "cannot handle response",
              });
            }
            throw new Error("login error");
          }
        })
        .catch((error) => {
          if (!ignoreErrors) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              this.setStateLoginFailure({
                error: error.response.data.error,
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              this.setStateLoginFailure({
                error: error.message || error,
              });
            }
          }
          throw new Error("login error");
        });
    },
    startLogin() {
      this.loading = true;
    },
    abortLogin() {
      this.loading = false;
    },
    setStateLogin({
      authToken,
      refreshToken,
      userId,
      role,
      rights,
      name,
      email,
    }) {
      const payload = parseJwt(authToken);
      this.authToken = authToken;
      this.refreshToken = refreshToken;
      this.exp = payload.exp;
      this.role = role;
      this.rights = rights;
      this.userId = userId.toString();
      this.name = name;
      this.email = email;
      this.error = null;
      this.loading = false;
      this.lastAuthEmail = email;
      persist({ authToken, refreshToken, userId });
      notifyAuthChange(this);
    },
    updateTokens({ authToken, refreshToken }) {
      const payload = parseJwt(authToken);
      this.authToken = authToken;
      this.refreshToken = refreshToken;
      this.exp = payload.exp;
    },
    setStateLoginFailure({ error }) {
      this.authToken = null;
      this.exp = null;
      this.role = null;
      this.rights = [];
      this.refreshToken = null;
      this.userId = null;
      this.name = null;
      this.email = null;
      this.error = error;
      this.loading = false;
      this.lastAuthEmail = null;
      persist(null);
      notifyAuthChange(this);
    },
    setStateAuthLost() {
      this.authToken = null;
      this.exp = null;
      this.role = null;
      this.rights = [];
      this.refreshToken = null;
      this.userId = null;
      this.name = null;
      this.email = null;
      this.error = null;
      this.loading = false;
      persist(null);
      notifyAuthChange(this);
    },
    markAsInited() {
      this.inited = true;
      notifyAuthChange(this);
    },
    getHeaders() {
      const { authToken } = this;
      const csrfToken = (document.querySelector("meta[name=csrf-token]") || {})
        .content;
      const headers = {
        "X-Env": location.host,
      };
      if (authToken) {
        headers.Authorization = "Bearer " + authToken;
      }
      if (csrfToken) {
        headers["X-CSRF-Token"] = csrfToken;
      }
      return headers;
    },
    logout() {
      if (!this.loggedIn) {
        return Promise.resolve(false);
      }
      this.startLogin();
      this.lastAuthEmail = null;
      return axios
        .delete("/users/sign_out", {
          headers: {
            ...this.getHeaders(),
          },
          responseType: "json",
        })
        .finally(() => {
          this.setStateAuthLost();
        });
    },
    authenticate({ email, password }) {
      this.startLogin();
      return this.wrapLoginAxiosRequest(
        axios.post(
          "/users/sign_in",
          {
            user: {
              email,
              password,
            },
          },
          {
            headers: {
              ...this.getHeaders(),
            },
            responseType: "json",
          }
        )
      );
    },
    authenticateViaStoredToken({ jwtToken, refreshToken }) {
      this.startLogin();
      return apolloProvider.defaultClient
        .mutate({
          mutation: gql`
            mutation tokenLogin {
              tokenLogin {
                id
                name
                email
                role
                rights
              }
            }
          `,
        })
        .then((response) => {
          const {
            data: { tokenLogin },
          } = response;
          if (tokenLogin) {
            if (this.requiredRole === tokenLogin.role) {
              this.setStateLogin({
                authToken: jwtToken,
                refreshToken: refreshToken,
                userId: tokenLogin.id.toString(),
                role: tokenLogin.role,
                rights: tokenLogin.rights,
                name: tokenLogin.name,
                email: tokenLogin.email,
              });
            } else {
              throw new Error(
                `You are role ${tokenLogin.role} but this login is for ${this.requiredRole}`
              );
            }
          } else {
            if (refreshToken) {
              return this.authenticateViaRefreshToken({ refreshToken });
            } else {
              this.setStateAuthLost();
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.setStateLoginFailure({
            error: error.message || error,
          });
        })
        .finally(() => {
          this.markAsInited();
        });
    },
    authenticateViaRefreshToken({ refreshToken }) {
      this.startLogin();
      return this.wrapLoginAxiosRequest(
        axios.post(
          "/users/sign_in",
          {},
          {
            headers: {
              ...this.getHeaders(),
              Authorization: "Token " + refreshToken,
            },
            responseType: "json",
          }
        )
      ).finally(() => {
        this.markAsInited();
      });
    },
    updateAuth({ authToken, refreshToken, userId }) {
      if (this.userId && this.userId !== userId.toString()) {
        console.log(
          "updateAuth currently logged in but different user",
          this.userId,
          userId
        );
        this.setStateAuthLost();
      } else {
        console.log(
          "updateAuth currently not logged in or same user logged in"
        );
        this.authenticateViaStoredToken({
          jwtToken: authToken,
          refreshToken,
        });
      }
    },
    refreshAuth() {
      this.startLogin();
      const refreshToken = this.refreshToken;
      return this.wrapLoginAxiosRequest(
        axios.post(
          "/users/sign_in",
          {},
          {
            headers: {
              ...this.getHeaders(),
              Authorization: "Token " + refreshToken,
            },
            responseType: "json",
          }
        ),
        true
      ).catch(() => {
        this.abortLogin();
      });
    },
  },
  render(createElement) {
    const { reLoginVisible, lastAuthEmail, error } = this;
    if (reLoginVisible) {
      return createElement("div", { class: "auth auth__re-login" }, [
        createElement(ReLoginOverlay, {
          props: {
            visible: true,
            email: lastAuthEmail,
            error: error,
            doAuthenticate: this.authenticate,
          },
        }),
      ]);
    }
    return createElement("div", { class: "auth" });
  },
});

const rootEl = document.createElement("div");
document.body.appendChild(rootEl);
ReactiveAuth.$mount(rootEl);

function hasRunningRefreshFromOtherTab() {
  const raw = localStorage.getItem(local_refreshing_key);
  if (!raw) {
    return false;
  }
  try {
    const data = JSON.stringify(raw);
    return data.id !== TAB_ID && data.c + 60 * 1000 < +new Date();
  } catch (e) {
    return false;
  }
}

let runningRefresh = null;
let refreshTimeout;

function cancelRemove() {
  if (refreshTimeout) {
    clearTimeout(refreshTimeout);
    refreshTimeout = null;
  }
}

function removeRefreshingKey() {
  cancelRemove();
  const exp2 = localStorage.getItem(local_refreshing_key);
  if (exp2) {
    try {
      const data = JSON.parse(exp2);
      if (data.id === TAB_ID) {
        localStorage.removeItem(local_refreshing_key);
      }
    } catch (e) {
      localStorage.removeItem(local_refreshing_key);
    }
  }
}

window.addEventListener("unload", removeRefreshingKey);

function markRefreshAsRunning() {
  cancelRemove();
  runningRefresh = {
    id: TAB_ID,
    c: +new Date(),
  };
  localStorage.setItem(local_refreshing_key, JSON.stringify(runningRefresh));
}

function markRefreshAsDone() {
  refreshTimeout = setTimeout(removeRefreshingKey, 1000);
}

export function init() {
  setInterval(function () {
    const exp = ReactiveAuth.exp;
    const refreshToken = ReactiveAuth.refreshToken;
    if (exp && refreshToken) {
      const millisecondsToExp = exp * 1000 - +new Date();
      if (millisecondsToExp <= 0) {
        // could not refresh the jwt, let's log out then
        if (!hasRunningRefreshFromOtherTab()) {
          // this code should only run in one tab at a time
          markRefreshAsRunning();
          ReactiveAuth.setStateAuthLost();
          markRefreshAsDone();
        }
      } else if (millisecondsToExp < 10 * 60 * 1000) {
        // try to refresh jwt in the last 10 minutes every minute
        if (!hasRunningRefreshFromOtherTab()) {
          // this code should only run in one tab at a time
          markRefreshAsRunning();
          ReactiveAuth.refreshAuth().finally(() => {
            markRefreshAsDone();
          });
        }
      }
    }
  }, 60000);
  window.addEventListener("storage", (e) => {
    switch (e.key) {
      case local_auth_key:
        console.log(`got new auth: ${e.newValue}`, e);
        const rawData = e.newValue;
        if (rawData) {
          try {
            const data = JSON.parse(rawData);
            console.log("about to auth/updateAuth", data);
            ReactiveAuth.updateAuth(data);
          } catch (e) {
            console.error("could not parse auth data", rawData, e);
            ReactiveAuth.setStateAuthLost();
          }
        } else {
          ReactiveAuth.setStateAuthLost();
        }
        break;
    }
  });

  function initAuthWhenReady(event) {
    if (event && event.key === local_refreshing_key) {
      if (!event.newValue) {
        setTimeout(initAuth, 0);
      }
    }
  }

  window.addEventListener("storage", initAuthWhenReady);

  function initAuth() {
    if (!hasRunningRefreshFromOtherTab()) {
      // this code should only run in one tab at a time
      markRefreshAsRunning();
      window.removeEventListener("storage", initAuthWhenReady);
      const { authToken, refreshToken } = load();
      if (authToken && refreshToken) {
        return ReactiveAuth.authenticateViaStoredToken({
          jwtToken: authToken,
          refreshToken,
        }).finally(() => {
          markRefreshAsDone();
        });
      } else if (refreshToken) {
        return ReactiveAuth.authenticateViaRefreshToken({
          refreshToken,
        }).finally(() => {
          markRefreshAsDone();
        });
      } else {
        ReactiveAuth.markAsInited();
        markRefreshAsDone();
      }
    }
  }

  initAuth();
  return Promise.resolve(true);
}

// https://stackoverflow.com/a/38552302
function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

let authChangeCallbacks;

export function onAuthChange(callback) {
  if (!authChangeCallbacks) {
    authChangeCallbacks = [];
  }
  authChangeCallbacks.push(callback);
  return function removeCallback() {
    authChangeCallbacks.splice(authChangeCallbacks.indexOf(callback), 1);
  };
}

function notifyAuthChange(auth) {
  if (authChangeCallbacks) {
    const authCopy = { ...auth };
    for (let i = 0; i < authChangeCallbacks.length; i++) {
      authChangeCallbacks[i](authCopy);
    }
  }
}
