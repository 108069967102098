<template>
  <static-page-chrome>
    <h1>Kontakt</h1>
    <h2>Fragen zu Klever:</h2>
    <p itemscope itemtype="http://schema.org/Person">
      <span itemprop="name">Stefanie Becker</span>
      <a :href="`mailto:s.becker${atDomain}`" itemprop="email"
        >s.becker{{ atDomain }}</a
      >
      <phone-number phone="+497211335622" itemprop="telephone">
        0721/1335622
      </phone-number>
    </p>
    <p itemscope itemtype="http://schema.org/Person">
      <span itemprop="name">Elena Erkeling</span>
      <a :href="`mailto:e.erkeling${atDomain}`" itemprop="email"
        >e.erkeling{{ atDomain }}</a
      >
      <phone-number phone="+497211335622" itemprop="telephone">
        0721/1335622
      </phone-number>
    </p>
    <p>
      Klever Website:
      <a
        href="https://www.klever-ka.de/"
        rel="noreferrer noopener"
        target="_blank"
        >www.klever-ka.de</a
      >
    </p>
    <h2>Fragen zur Bezahlung:</h2>
    <p itemscope itemtype="http://schema.org/Person">
      <span itemprop="name">Tanja Karl</span>
      <a :href="`mailto:t.karl${atDomain}`" itemprop="email"
        >t.karl{{ atDomain }}</a
      >
      <phone-number phone="+497211335621" itemprop="telephone">
        0721/1335621
      </phone-number>
    </p>
  </static-page-chrome>
</template>

<script>
import StaticPageChrome from "~/components/common/layout/StaticPageChrome";
import PhoneNumber from "~/components/common/utils/PhoneNumber";
export default {
  components: {
    PhoneNumber,
    StaticPageChrome,
  },
  computed: {
    atDomain() {
      return "@stja.de";
    },
  },
  head: {
    title: "Kontakt",
  },
};
</script>
