<template>
  <el-main :class="$style.container" class="Content Content_centered">
    <div>
      <logo :class="$style.logo" :triangle-color="triangleColor" />
      <div :class="$style.whatIsThis">
        <el-alert
          :closable="false"
          effect="dark"
          title="404"
          type="warning"
          show-icon
        >
          Diese Seite kennen wir nicht.
        </el-alert>
      </div>
    </div>
  </el-main>
</template>

<script>
import Logo from "../components/common/layout/Logo.vue";
import { STJA_COLORS } from "../util/color";

function getRandomColor() {
  const possibleColors = [
    "tuerkis",
    "signalgruen",
    "gelbgruen",
    "maingruen",
    "gelb",
    "orange",
    "signalrot",
    "kirschrot",
    "purpur",
    "flieder",
    "dunkelblau",
    "mittelblau",
  ];

  return STJA_COLORS[
    possibleColors[Math.floor(Math.random() * possibleColors.length)]
  ];
}

export default {
  components: {
    Logo,
  },
  data() {
    return {
      triangleColor: getRandomColor(),
    };
  },
  mounted() {
    this._setTriangleColorInterval = setInterval(() => {
      this.triangleColor = getRandomColor();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this._setTriangleColorInterval);
  },
  methods: {
    register() {
      return this.$router.push("/register");
    },
    profile() {
      return this.$router.push("/me");
    },
  },
  head: {
    title: "Seite nicht gefunden",
  },
};
</script>

<style lang="module.scss" module>
.container {
  /*text-align: center;*/
}

.logo {
  margin-top: 30px;
  width: 50vw;
  min-width: 250px;
  max-width: 400px;
  height: auto;
}

.whatIsThis {
  padding: 15px 10px 0;
  max-width: 600px;

> * {
  margin-top: 1em;

&
:first-child {
  margin-top: 0;
}

}
}
</style>
