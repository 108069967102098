<template>
  <static-page-chrome>
    <h1>Datenschutz­erklärung</h1>
    <p>
      <strong
        >stja@school ist ein Angebot des Stadtjugendausschuss e.V.
        Karlsruhe.</strong
      >
    </p>
    <p>
      Hinweise zur Erhebung und Verarbeitung personenbezogener Daten bei Aufruf
      dieser Webseite finden Sie in der Datenschutzerklärung der Hauptseite
      <a href="https://www.stja.de/" target="_blank" rel="noreferrer noopener"
        >www.stja.de</a
      >, die über folgenden Link erreichbar ist:
      <a
        href="https://stja.de/datenschutz/"
        target="_blank"
        rel="noreferrer noopener"
        >https://stja.de/datenschutz/</a
      >
    </p>

    <h2>
      Ergänzende Informationen zur Erhebung und Verarbeitung personenbezogener
      Daten bei Nutzung des Angebotes stja@school
    </h2>

    <p>
      Wir erheben personenbezogene Daten zu der erziehungsberechtigten Person
      und zu dem Kind bzw. den Kindern, die für das auf dieser Website
      verfügbare Betreuungs-Angebot angemeldet werden sollen. Diese Daten werden
      benötigt, um die gebuchten Leistungen erbringen zu können. Rechtsgrundlage
      der Erhebung und Verarbeitung ist Art. 6 Abs. 1 lit. b DSGVO.
    </p>

    <p>
      Dies gilt auch für erhobenen Daten für die Erteilung des
      SEPA-Lastschriftmandates. Diese Daten werden zum Zwecke der Abbuchung per
      Datentransfer an das von Ihnen angegebene Kreditinstitut übermittelt.
    </p>
  </static-page-chrome>
</template>

<script>
import StaticPageChrome from "~/components/common/layout/StaticPageChrome";
export default {
  components: {
    StaticPageChrome,
  },
  head: {
    title: "Datenschutzerklärung",
  },
};
</script>
