export const STJA_COLORS = {
  tuerkis: "#16a1c0",
  signalgruen: "#2a9f70",
  gelbgruen: "#74b342",
  maingruen: "#d6df2a",
  gelb: "#d6df2a",
  orange: "#db9e27",
  signalrot: "#c41440",
  kirschrot: "#c60072",
  purpur: "#870d88",
  flieder: "#601588",
  dunkelblau: "#351788",
  mittelblau: "#1e89cf",
  lichtgruen: "#abd1ce",
  hellgelb: "#fefcd8",
  rosa: "#dea49a",
  hellblau: "#a6c3e9",
};
