export const DIRECTION_FORWARD = ">";
export const DIRECTION_BACKWARD = "<";

export function getEmptyPaging() {
  return {
    direction: DIRECTION_FORWARD,
    cursor: null,
  };
}

export function getEmptyConnection() {
  return {
    nodes: [],
    edges: [],
    pageInfo: {
      endCursor: null,
      startCursor: null,
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalCount: null,
  };
}

export function getPagingVariables(paging) {
  const { direction, cursor } = paging;
  if (direction === DIRECTION_FORWARD) {
    return {
      first: 10,
      after: cursor,
    };
  } else {
    return {
      last: 10,
      before: cursor,
    };
  }
}

export function serializeBool(bool) {
  if (bool === true) {
    return "true";
  }
  if (bool === false) {
    return "false";
  }
  return "null";
}
export function deserializeBool(str) {
  if (str === "true") {
    return true;
  }
  if (str === "false") {
    return false;
  }
  return null;
}
export function serializeStringArray(ary) {
  return ary.join(",");
}
export function deserializeStringArray(str) {
  if (!str || !str.trim()) {
    return [];
  }
  return str.split(",");
}
