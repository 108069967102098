import { Message, MessageBox } from "element-ui";
import gql from "graphql-tag";

export class ValidationError extends Error {
  //
}

export function ensureValidForm(form, onSuccess) {
  return form
    .validate()
    .catch((e) => {
      Message.error("Korrigieren Sie die Fehler im Formular");
      return Promise.reject(new ValidationError("Form invalid"));
    })
    .then(() => {
      return onSuccess();
    });
}

export function showError(error) {
  return MessageBox.alert(error, "Fehler");
}

export function getErrorMsg(key, details, messages) {
  const translated = [];
  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];
    const detail = details[i];
    switch (detail.error) {
      case "too_short":
        translated.push(`Zu kurz (weniger als ${detail.count} Zeichen)`);
        break;
      case "too_long":
        translated.push(`Zu lang (mehr als ${detail.count} Zeichen)`);
        break;
      case "taken":
        if (key === "email") {
          translated.push(
            "Wir haben schon einen Account mit dieser E-Mail-Adresse"
          );
        } else {
          translated.push(`${detail.value} schon vorhanden`);
        }
        break;
      case "pwned_password":
        translated.push(
          "In öffentlichen Passwortlisten (bitte wählen Sie ein stärkeres)"
        );
        break;
      case "wrong_current_password":
        translated.push("Ihr aktuelles Passwort stimmt nicht");
        break;
      case "invalid":
        if (key === "unlock_token") {
          translated.push(
            "Der Entsperr-Link ist nicht (mehr) gültig. Vielleicht ist Ihr Konto schon entsperrt?"
          );
        } else {
          translated.push("Ist nicht gültig");
        }
        break;
      default:
        translated.push(msg);
    }
  }
  return translated.join(", ");
}

export function parseServerErrors(serverErrors, validKeys) {
  const parsedErrors = {};
  const allServerErrors = [];
  for (let i = 0; i < serverErrors.length; i++) {
    const err = serverErrors[i];
    const msg = getErrorMsg(err.key, err.details, err.messages);
    allServerErrors.push(msg);
    parsedErrors[err.key] = msg;
  }
  let foundOne = false;
  for (let i = 0; i < validKeys.length; i++) {
    const k = validKeys[i];
    if (!parsedErrors[k]) {
      parsedErrors[k] = "";
    } else {
      foundOne = true;
    }
  }
  return { parsedErrors, allServerErrors, foundOne };
}

export function hasServerErrors(payload) {
  return payload && payload.errors && payload.errors.length > 0;
}

export function parseServerErrorsAndShowMessage(serverErrors, validKeys) {
  let { parsedErrors, allServerErrors, foundOne } = parseServerErrors(
    serverErrors,
    validKeys
  );

  if (foundOne) {
    Message.error(
      "Der Server meldet Eingabefehler. Korrigieren Sie die Fehler im Formular"
    );
  } else {
    return MessageBox.alert(allServerErrors.join("\n"), "Fehler");
  }

  return parsedErrors;
}

export function getPasswordPolicyValidator($apollo) {
  return function (rule, value, callback) {
    if (value) {
      $apollo
        .mutate({
          mutation: gql`
            mutation checkPasswordPolicy($password: String!) {
              checkPasswordPolicy(password: $password) {
                key
                messages
                details {
                  error
                  count
                  value
                }
              }
            }
          `,
          variables: {
            password: value,
          },
        })
        .then(({ data: { checkPasswordPolicy } }) => {
          if (checkPasswordPolicy) {
            const msg = getErrorMsg(
              checkPasswordPolicy.key,
              checkPasswordPolicy.details,
              checkPasswordPolicy.messages
            );
            callback(new Error(msg));
          } else {
            callback();
          }
        });
    } else {
      callback();
    }
  };
}

export function getIbanValidator(
  $apollo,
  ibanDetailsCallback = function () {}
) {
  return function (rule, value, callback) {
    if (value) {
      $apollo
        .mutate({
          mutation: gql`
            mutation checkIban($iban: String!) {
              checkIban(input: { iban: $iban }) {
                iban
                countryCode
                bankName
                accountNumber
                bankCode
              }
            }
          `,
          variables: {
            iban: value,
          },
        })
        .then(({ data: { checkIban } }) => {
          ibanDetailsCallback(checkIban, rule, value);
          if (checkIban) {
            callback();
          } else {
            callback(new Error(rule.message || "not a valid IBAN"));
          }
        });
    } else {
      ibanDetailsCallback(undefined, rule, value);
      callback();
    }
  };
}

export function getPhoneValidator(
  $apollo,
  phoneDetailsCallback = function () {}
) {
  return function (rule, value, callback) {
    if (value) {
      $apollo
        .mutate({
          mutation: gql`
            mutation checkPhone($phone: String!) {
              checkPhone(input: { phone: $phone }) {
                valid
                formatted
                info
              }
            }
          `,
          variables: {
            phone: value,
          },
        })
        .then(({ data: { checkPhone } }) => {
          phoneDetailsCallback(checkPhone, rule, value);
          if (checkPhone && checkPhone.valid) {
            callback();
          } else {
            callback(new Error(rule.message || "not a valid Phone"));
          }
        });
    } else {
      phoneDetailsCallback(undefined, rule, value);
      callback();
    }
  };
}

const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isEmail(value) {
  return (
    value &&
    typeof value === "string" &&
    !!value.match(EMAIL_PATTERN) &&
    value.length < 255
  );
}
