<template>
  <el-main class="Content Content_centered">
    <app-panel :class="$style.panel" main>
      <h1 slot="title" class="AppTitle">
        {{ title }}
      </h1>
      <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-position="top"
        label-suffix=": "
        @submit.native.prevent
      >
        <el-form-item label="E-Mail-Adresse" prop="email">
          <el-input
            id="email"
            v-model="model.email"
            autofocus
            @keypress.native.enter="$refs.passwordInput.focus()"
          />
        </el-form-item>
        <el-form-item label="Passwort" prop="password">
          <el-input
            id="password"
            ref="passwordInput"
            v-model="model.password"
            type="password"
            @keypress.native.enter="submit().catch(() => {})"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit().catch(() => {})">
            Login
          </el-button>
          <auth-meta-navi v-if="!skipMetaNav" />
        </el-form-item>
      </el-form>
    </app-panel>
  </el-main>
</template>

<script>
import AppPanel from "~/components/common/layout/AppPanel";
import AuthMetaNavi from "~/components/common/layout/AuthMetaNavi";
import { ensureValidForm } from "~/util/validation";

function getEmpty() {
  return {
    email: "",
    password: "",
  };
}

export default {
  components: { AuthMetaNavi, AppPanel },
  props: {
    title: {
      type: String,
      default: "Login",
    },
    skipMetaNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: getEmpty(),
    };
  },
  computed: {
    rules() {
      return {
        password: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "Muss eine valide E-Mail-Adresse sein",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    submit() {
      return ensureValidForm(this.$refs.form, () => {
        const { email, password } = this.model;
        return this.$auth
          .authenticate({
            email,
            password,
          })
          .then((...args) => {
            this.$message({
              type: "success",
              message: "Sie sind nun eingeloggt",
              offset: 60,
            });
            this.$router.replace({ name: "index" });
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: this.$auth.error,
              offset: 60,
            });
          });
      });
    },
  },
  head() {
    return {
      title: "Login",
    };
  },
};
</script>

<style lang="module.scss" module>
.panel {
  width: 400px;
  max-width: calc(100vw - 40px);
}
</style>
