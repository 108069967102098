export function possibleSalutations() {
  return ["Hallo", "Sehr geehrte Frau", "Sehr geehrter Herr"];
}

export function possibleSchools() {
  return ["Helmholtz Gymnasium", "Schulzentrum Neureut"];
}

export const POSSIBLE_RIGHTS = {
  all: "Administratives Recht (darf alles)",
  evidences_management: "Nachweise-Überprüfung",
  subscription_management: "Aboverwaltung",
  accounting: "Buchhaltung",
  services_management: "Leistungs-Verwaltung",
  employee_management: "Mitarbeitende-Verwaltung",
  caregiver: "Betreuer*in",
};
