import { escapeHTML } from "~/util/html";

export function subscriptionsTypes() {
  return [
    "NEW_SUBSCRIPTION",
    "SUBSCRIPTION_ABOUT_TO_RENEW",
    "SUBSCRIPTION_CANCELED",
    "SUBSCRIPTION_CONFIGURATION_ACTUALIZED",
    "SUBSCRIPTION_CONFIGURATION_UPDATED",
    "SUBSCRIPTION_CONTACTS_CHANGED",
    "SUBSCRIPTION_DESTROYED",
    "SUBSCRIPTION_RENEWED",
    "SUBSCRIPTION_UNCANCELED",
    "BULK_MESSAGE", // not really true, but anyway...
    "SUBSCRIPTION_ABOUT_TO_END",
    "SUBSCRIPTION_ACCEPTED",
    "SUBSCRIPTION_REJECTED",
    "SUBSCRIPTION_PRICE_CHANGED",
    "SUBSCRIPTION_EXTENDED",
  ];
}

export function evidencesTypes() {
  return [
    "EVIDENCE_ACCEPTED",
    "EVIDENCE_DECLINED",
    "EVIDENCE_RECEIVED",
    "EVIDENCE_REMINDER",
  ];
}

export function bankAccountsTypes() {
  return ["NEW_SEPA_MANDATE", "SEPA_PRENOTIFICATION"];
}

export function wrap(object, employee, text) {
  const trailer = employee
    ? `
<p><br /><i>Bearbeitung: ${escapeHTML(employee)}</i></p>
`
    : "";
  if (object.user) {
    return `<p>${escapeHTML(
      object.user.fullSalutation
    )},<br /></p><p>${text}</p>${trailer}`;
  } else {
    return `<p>${text}</p>${trailer}`;
  }
}
