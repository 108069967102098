<template>
  <static-page-chrome>
    <h1>Impressum</h1>
    <h2>Angaben gemäß § 5 TMG:</h2>
    <p>
      <span>Stadtjugendausschuss</span> e. V. Karlsruhe<br >
      Moltkestr. 22<br >
      76133 Karlsruhe
    </p>
    <p>
      Postfach 11 13 02<br >
      76063 Karlsruhe
    </p>
    <h2>Vertreten durch:</h2>
    <p>
      Vorsitzender: Marco Dawid<br >
      Geschäftsführung: Daniel Melchien
    </p>
    <h2>Kontakt:</h2>
    <p>Telefon: +49 (0) 721 13356-01</p>
    <p>Telefax: +49 (0) 721 13356-09</p>
    <p>E-Mail: stja@karlsruhe.de</p>
    <h2>Registereintrag:</h2>
    <p>
      Eingetragen im Vereinsregister des Amtsgerichts Mannheim.<br >
      Registernummer: VR 100155
    </p>
    <h2>Umsatzsteuer-ID:</h2>
    <p>
      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br >
      DE 143 610 472
    </p>
    <h2>Aufsichtsbehörde:</h2>
    <p>
      Stadt Karlsruhe; Kommunalverband für Jugend und Soziales
      Baden-Württemberg.
    </p>
  </static-page-chrome>
</template>

<script>
import StaticPageChrome from "~/components/common/layout/StaticPageChrome";
export default {
  components: {
    StaticPageChrome,
  },
  head: {
    title: "Impressum",
  },
};
</script>
