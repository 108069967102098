import Vue from "vue";
import VueApollo from "vue-apollo";
import VueRouter from "vue-router";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/de";
import VueMeta from "vue-meta";
import "~assets/element-ui.scss";
import "~assets/base-styles.scss";
import "~/util/silence-async-validator";
import { URLS } from "~/util/rails.js.erb";
import { ReactiveAuth } from "~/util/auth";

let configured = false;
export function configureVue() {
  if (!configured) {
    Vue.use(VueApollo);
    Vue.use(VueRouter);
    Vue.use(Element, { locale });
    Vue.use(VueMeta, {
      refreshOnceOnNavigation: true,
      keyName: "head",
    });
  }
}

export function createApp(rootProps = {}) {
  configureVue();

  Vue.prototype.$urls = URLS;
  Vue.prototype.$auth = ReactiveAuth;

  Vue.prototype.$apolloErrorHandle = function (error) {
    Element.MessageBox.alert(error, "Fehler");
  };

  return new Vue(rootProps);
}
